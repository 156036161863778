import React, { Component } from 'react';
import { FiChevronUp } from 'react-icons/fi';
import ScrollToTop from 'react-scroll-up';
import PageHelmet from '../component/common/Helmet';
import Footer from '../component/footer/Footer';
import Header from '../component/header/Header';

class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Service Details" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.svg"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--23"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">DRIFT OCH UNDERHÅLL</h2>
                  <p>Säkerställ att dina tjänster är driftsäkra</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <picture>
                            <source
                              className="w-100"
                              srcSet="/assets/images/service/amazon.webp"
                              type="image/webp"
                            />
                            <img
                              className="w-100"
                              src="/assets/images/service/amazon.jpg"
                              alt="Service Images"
                            />
                          </picture>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>
                            Att ha ett driftsäkert system är viktigare än
                            någonsin. För varje 0,1 sekunder i laddtid förlorar
                            Amazon 16 miljarder i försäljning. Det har gjort dem
                            experter på snabba laddtider och driftsäkra system.
                          </p>
                          <p>
                            Vi ser till så din sida finns i flera datacenter
                            nära din kund. Om ett går ner kommer då sidan laddas
                            från en annan plats. Även viktigt är att data sparas
                            nära din användare i en så kallad CDN.
                          </p>
                          <h4 className="title">Process</h4>
                          <ul className="liststyle">
                            <li>Utvärderar nuvarande system och risker</li>
                            <li>
                              Flyttar över data till nya, snabba datacenter
                            </li>
                            <li>
                              Ser till så att servrarna ligger uppe dygnet runt
                              på flera platser
                            </li>
                            <li>Sätter upp CDNs för att minska laddtid</li>
                            <li>
                              Mäter laddtider och fortsätter drifta systemen
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default ServiceDetails;
